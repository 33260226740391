import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sb-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  standalone: false, // eslint-disable-line
})
export class WarningDialogComponent {
  data = inject<{
    title: string;
    message: string;
    confirmText: string;
    cancelText?: string;
    variant?: 'basic' | 'primary' | 'warn' | 'danger' | 'success';
  }>(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<WarningDialogComponent>>(MatDialogRef);

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
