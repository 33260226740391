import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { WarningDialogComponent } from './warning-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WarningDialogService {
  private dialog = inject(MatDialog);

  dialogConfig: MatDialogConfig = {
    autoFocus: false,
    maxWidth: '600px',
  };

  dialogRef?: MatDialogRef<WarningDialogComponent>;

  /**
   *
   * @param @Optional dialogConfig Component wise dialog config.
   *  Components can pass down data in this config or they can assign the data to dialogState
   */
  open(dialogConfig?: MatDialogConfig): MatDialogRef<WarningDialogComponent> {
    this.dialogRef = this.dialog.open(WarningDialogComponent, {
      ...this.dialogConfig,
      ...dialogConfig,
    });

    return this.dialogRef;
  }
}
